import { GET, POST } from "../helpers/requests";

/**
 * @return {{
 *  data: {subscription: Boolean},
 *  error: Object
 * }}
 */
const hasSubscription = async () => {
  const resp = await GET("/payment/subscription");
  return resp;
};

const hasNoUpgrade = async () => {
  const resp = await GET("/payment/noupgrade");
  return resp;
};

const hasMollieId = async () => {
  const resp = await GET("/payment/mollieid");
  return resp;
};

const isDemoUpgraded = async () => {
  const resp = await GET("/payment/demoupgraded");
  return resp;
};

const isDemoExpired = async () => {
  const resp = await GET("/payment/demoexpired");
  return resp;
};

/**
 *
 * @param {String} couponCode
 * @return {Price}
 */
const getPrice = async couponCode => {
  const resp = await POST("/payment/price", { couponCode });
  if (resp.error) return { error: resp.error };
  return resp.data;
};

/**
 *
 * @param {String} redirectUrl url to redirect the user after payment
 * @return {{
 *  _id: String,
 *  link: String,
 *  error: Object
 * }}
 */
const pay = async (redirectUrl, couponCode) => {
  const resp = await POST("/payment/pay", { redirectUrl, couponCode });
  if (resp.error) return { error: resp.error };
  return resp.data;
};

/**
 * @return {Boolean}
 */
const isPaid = async () => {
  const resp = await GET("/payment/isPaid");
  return resp && resp.data && resp.data.isPaid;
};

export default {
  hasSubscription,
  getPrice,
  pay,
  isPaid,
  hasNoUpgrade,
  hasMollieId,
  isDemoUpgraded,
  isDemoExpired
};
