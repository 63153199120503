import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import IngredientCard from "../components/Ingredients/IngredientCard";
import Loader from "../components/loader";
import Protected from "../routes/Protected";
import { getNameCategoryById, getNameBrandById } from "../utils/index";
import { GET } from "../helpers/requests";
import { canRead, canWrite, getLanguage, getRole } from "../helpers/auth";
import IngredientAPI from "../backend/ingredient";
import DeleteIngredient from "../components/Ingredients/DeleteIngredient";
import RecipeAPI from "../backend/recipe";
import BrandAPI from "../backend/brand";
import SimpleSnackbar from "../components/SimpleSnackbar";
import { PageToolbarContainer } from "src/components/drawer";
import IngredientsPageToolbar from "../components/Ingredients/ingredientsPageToolbar";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchControler from "src/utils/filter";

const l = getLanguage();

export default function Ingredients() {
  const [loading, setLoading] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [allIngredients, setAllIngredients] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filterSearch, setFilterSearch] = useState({ start: 0, limit: 5000 });
  const [ingDelete, setIngDelete] = useState({
    id: undefined,
    name: undefined,
  });
  const [contents, setContents] = useState([]);
  const [fitFor, setFitFor] = useState([]);

  const [snack, setSnack] = useState({
    snackMessage: "",
    snackVariant: "danger",
    snackOpen: false,
  });

  const history = useHistory();

  useEffect(() => {
    loadData();

    // eslint-disable-next-line
  }, []);

  const loadCategory = async () => {
    const categoriesPromise = await GET("/ingredient/category");
    if (categoriesPromise.error) {
      return console.error(categoriesPromise.error.message);
    }
    setCategories(categoriesPromise.data);
  };

  const loadBrands = async () => {
    //const brandData = await GET('/brand')
    const brandData = await BrandAPI.get();
    if (brandData.error) {
      return console.error(brandData.error.message);
    }
    setBrands(brandData.data);
  };

  const loadData = async () => {
    setLoading(true);
    console.log("quivi");
    await Promise.all([loadCategory(), loadBrands(), loadFitFor(), loadContents(), loadFilter(), getIngredients()]);
    setLoading(false);
  };

  const loadFilter = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("name")) {
      filterSearch.name = urlParams.get("name");
    } else {
      delete filterSearch.name;
    }

    if (urlParams.has("brand")) {
      filterSearch.brandId = urlParams.get("brand");
    } else {
      delete filterSearch.brandId;
    }

    if (urlParams.has("category")) {
      filterSearch.categoryId = urlParams.get("category");
    } else {
      delete filterSearch.categoryId;
    }
    setFilterSearch(filterSearch);
  };

  const loadFitFor = async () => {
    const result = await RecipeAPI.getAllFitFors();
    setFitFor(result);
  };

  const loadContents = async () => {
    const result = await RecipeAPI.getAllContents();
    setContents(result);
  };

  const getIngredients = async () => {
    setLoading(true);
    const response = await IngredientAPI.getAll();
    if (response.error) {
      return console.error(response.error.message);
    }

    const data = response.data;

    data.forEach(ing => {

      ing.name = ing.nameTranslation && ing.nameTranslation[l] ? ing.nameTranslation[l] : ing.name;
      ing.commercialName =
        ing.commercialNameTranslation && ing.commercialNameTranslation[l] ? ing.commercialNameTranslation[l] : ing.commercialName;
    });

    setAllIngredients(response.data.sort((ing1, ing2) => ing1.name.localeCompare(ing2.name)));
   
    setLoading(false);
  };

  const setFilter = filter => {
    setFilterSearch(filter);
  };

  const closeIngDelete = () => {
    setIngDelete({
      id: undefined,
      name: undefined,
    });
  };

  const handleCloseSnack = () => {
    setSnack({ ...snack, snackMessage: "", snackOpen: false });
  };

  const deletedIngredient = async ingId => {
    await IngredientAPI.deleteById(ingId);
    const ingredientsUpdated = ingredients.filter(ingredients => ingredients._id !== ingId);
    setIngredients(ingredientsUpdated);
  };
  const ingredientsPerPage = 40;

  const ingFiltered = allIngredients.filter(ing => {
    let valid = true;
    if (filterSearch.name)
      valid =
        SearchControler.isSearchingFit(ing.name, filterSearch.name) ||
        SearchControler.isSearchingFit(ing.commercialName, filterSearch.name);

    if (filterSearch.brandId) valid = valid && ing.brandId === filterSearch.brandId;
    if (filterSearch.categoryId) valid = valid && ing.categoryId === filterSearch.categoryId;
    return valid;
  });

  const ingredientsToShow2 = ingFiltered.slice(0, pageNumber * ingredientsPerPage);

  return (
    <Protected
      role={getRole()}
      perform="payment:must-pay"
      data={{
        canWrite: canWrite(),
        canRead: canRead(),
      }}
      yes={() => (
        <>
          <PageToolbarContainer>
            <IngredientsPageToolbar
              brands={brands}
              categories={categories}
              //getIngredientsFilter={getIngredientsFilter}
              filterSearch={filterSearch}
              setFilterSearch={setFilter}
              setLoading={setLoading}
              snack={snack}
              setSnack={setSnack}
              handleCloseSnack={handleCloseSnack}
            />
          </PageToolbarContainer>
          <div className="mt-3">
            <div className="mx-2 page-recipes justify-content-center">
              {loading ? (
                <Loader />
              ) : (
                <Row>
                  {/*ingredientsToShow.map((ingredient, index) => {
                    const name = getNameCategoryById(categories, ingredient.categoryId);
                    const brand = getNameBrandById(brands, ingredient.brandId);
                    return (
                      <Col key={ingredient._id} className="col-auto mb-3">
                        <IngredientCard
                          key={index}
                          position={index}
                          ingredient={ingredient}
                          categoryName={name}
                          brand={brand}
                          //reloadIngredients={getIngredientsFilter}
                          deleteIng={setIngDelete}
                          contents={contents}
                          fitfors={fitFor}
                          filterSearch={filterSearch}
                        />
                      </Col>
                    );
                  })*/}
                  <InfiniteScroll
                    dataLength={ingredientsToShow2.length}
                    hasMore={ingredientsToShow2.length < ingFiltered.length}
                    next={() => {
                      setPageNumber(pageNumber + 1);
                    }}
                    loader={<Loader />}
                    //scrollableTarget={"root"}
                    className="flex flex-wrap"
                  >
                    {ingredientsToShow2.map((ingredient, index) => {
                      const name = getNameCategoryById(categories, ingredient.categoryId);
                      const brand = getNameBrandById(brands, ingredient.brandId);
                      return (
                        <Col key={ingredient._id} className="col-auto mb-3">
                          <IngredientCard
                            key={index}
                            position={index}
                            ingredient={ingredient}
                            categoryName={name}
                            brand={brand}
                            reloadIngredients={() => null}
                            deleteIng={setIngDelete}
                            contents={contents}
                            fitfors={fitFor}
                            filterSearch={filterSearch}
                          />
                        </Col>
                      );
                    })}
                  </InfiniteScroll>
                </Row>
              )}
            </div>
          </div>
          <DeleteIngredient
            show={ingDelete.id !== undefined}
            handleClose={closeIngDelete}
            ingredientId={ingDelete.id}
            ingredientName={ingDelete.name}
            nextDeleteConfirm={deletedIngredient}
          />
          <SimpleSnackbar open={snack.snackOpen} variant={snack.snackVariant} message={snack.snackMessage} handleClose={handleCloseSnack} />
        </>
      )}
      no={() => {
        history.push("/payment");
        window.location.reload();
      }}
    />
  );
}
